<template>
  <div id="app">
    <div class="title">mxth.</div>
    <div class="subtitle">Max Theisen, Webdeveloper.</div>
    <div class="contact" @click="sendMail">{{ emailDecoded }}</div>
    <div class="info">
        <div class="column">
            <span>Max Theisen</span>
            <span>Greinergasse 18/41</span>
            <span>1190 Wien</span>
            <span>UID: ATU69441534</span>
            <span>Unternehmensgegenstand: IT-Dienstleistung</span>
        </div>
        <div class="column">
            <span>Mitglied bei: WKÖ</span>
            <span>Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at</span>
            <span>Aufsichtsbehörde: Magistratisches Bezirksamt des XIX. Bezirkes</span>
            <span>Verleihungsstaat: Österreich</span>
        </div>
    </div>
    <div class="legal">
      no cookies, no information stored.
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      email: 'aW5mb0BteHRoLmF0'
    }
  },
  methods: {
    sendMail() {
      let a = document.createElement('a')
      a.setAttribute('href', `mailto:${this.emailDecoded}`)
      a.click()
    }
  },
  computed: {
    emailDecoded() {
      return atob(this.email)
    }
  }
}
</script>

<style lang="scss">
body,html {
    margin: 0;
    background: #f5f5f5;
    height: 100%;
}
#app {
    color: #302f2f;
    font-family: 'Merriweather', serif;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        font-size: 2em;
        margin-bottom: 20px;
    }
    .subtitle {
        font-size: 1.2em;
        margin-bottom: 20px;
    }
    .contact {
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .info {
        color: #696969;
        display: inline-grid;
        grid-template-columns: 50% 50%;
        @media screen and (max-width: 600px) {
            grid-template-columns: 100%;
        }
        margin-bottom: 20px;
        span {
            font-size: 0.6em;
            line-height: 1.5em;
            display: block;
        }
    }
    .legal {
        font-size: 0.6em;
    }
}
</style>
